<template>
  <section>
    <b-row class="match-height">
      <div class="col-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Course Categories ({{ categories.length }})
            </h4>
          </div>
          <div class="card-datatable table-responsive">
            <table class="datatables-ajax table ">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th class="text-center">Courses</th>
                  <th>Description</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th class="text-center">Courses</th>
                  <th>Description</th>
                  <th class="text-center">Actions</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ category.name }}
                  </td>
                  <td class="text-center">
                    <a href="javascript:;">
                      {{ category.courses.length }}
                    </a>
                  </td>
                  <td>{{ category.description }}</td>
                  <td class="text-center">
                    <b-button-group>
                      <b-link
                        :to="{ name: 'apps-category-edit', params: { id: category.id } }"
                        class="font-weight-bold d-block text-nowrap btn btn-sm btn-primary"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="EditIcon"
                          size="15"
                        />  
                      </b-link>
                      <b-button 
                        type="button" 
                        class="btn-danger btn-sm"
                        @click="deleteCategory(category)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="DeleteIcon"
                          size="15"
                        />  
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-row>  
  </section>
</template>

<script>
import {
  BRow, BLink, BAvatar, BButtonGroup, BButton, BModal, BAlert, BCard, BCardText, BCardBody, BSpinner,
} from 'bootstrap-vue'
import Button from '@/views/components/button/Button.vue'

export default {
  components: {
    BRow,
    BLink,
    BAvatar,
    BButtonGroup,
    BButton,
    BModal,
    BSpinner,
    BAlert,
    BCard,
    BCardText,
    BCardBody,
  },
  data() {
    return {
      data: {},
      categories: [],
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    deleteCategory(category) {
      this.$swal({
        title: `Delete ${category.name}`,
        html: '<div class=" text-center mt-4"><h4 class="text-danger mb-3">Are you sure you want to delete this ?</h4></div>',
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary text-primary ml-1',
        },        
      }).then(result => {
        if (result.value) {
          this.deleteSelected(category.id)
        } 
      })
    },
    getCategories() {
      this.$http.get(`${this.$url}/category/`)
        .then(response => {
          console.log(response)
          if(response.data.status) {
            this.categories = response.data.data.data
          }
        })
    },
    deleteSelected(id) {
      this.$http.delete(`${this.$url}/category/${id}`)
        .then(response => {
          console.log(response)
          if(response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(res => {
              window.location.reload()
            })
          }
        })
        .catch( err => {
          if(err.response) {
            let message
            if(err.response.status != 200) {
              if(err.response.data.errors) {
                let errors = err.response.data.errors
                let errorList = Object.values(errors)
                errorList.map(msg => {
                    message = msg
                })
              }
              this.$swal({
                title: 'Error',
                text: err.response.data.message || message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-secondary',
                },
              })  
            }        
          }          

        })
    },
  },
}
</script>
